:root {
  --bs-body-color: var(--bs-black);
  --bs-link-color: var(--bs-black);
  --bs-link-color-rgb: var(--bs-black-rgb);
  --bs-link-hover-color: var(--bs-body-color);
  --bs-link-hover-color-rgb: 33, 37, 41;
  --bs-light-rgb: 242, 242, 242;

  --bs-warning: rgba(255, 208, 89, 1);
  --bs-body-font-family:
    'Inter', 'Helvetica Neue', Helvetica, system-ui, -apple-system, 'Segoe UI', Roboto,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';

  --padding-xs3: 0.125rem; /* 2px */
  --padding-xs2: 0.25rem; /* 4px */
  --padding-xs1: 0.375rem; /* 6px */
  --padding-xs: 0.5rem; /* 8px */
  --padding-sm: 0.75rem; /* 12px */
  --padding-base: 1rem; /* 16px */
  --padding-md: 1.25rem; /* 20px */
  --padding-lg: 1.5rem; /* 24px */
  --padding-xl: 2rem; /* 32px */
  --padding-xl1: 2.5rem; /* 40px */
  --padding-xl2: 3rem; /* 48px */
  --padding-xl3: 4rem; /* 64px */
  --padding-xl4: 5rem; /* 80px */
  --padding-xl5: 6rem; /* 96px */
  --padding-xl6: 7rem; /* 112px */

  --font-size-xs4: 0.375rem; /* 6px */
  --font-size-xs3: 0.5rem; /* 8px */
  --font-size-xs2: 0.625rem; /* 10px */
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-sm: 0.875rem; /* 14px */
  --font-size-base: 1rem; /* 16px */
  --font-size-md: 1.125rem; /* 18px */
  --font-size-lg: 1.25rem; /* 20px */
  --font-size-xl: 1.375rem; /* 22px */
  --font-size-xl2: 1.5rem; /* 24px */
  --font-size-xl3: 1.625rem; /* 26px */
  --font-size-xl4: 1.75rem; /* 28px */
  --font-size-xl5: 1.875rem; /* 30px */
  --font-size-xl6: 2rem; /* 32px */
  --font-size-xl6-2: 2.125rem; /* 34px */
  --font-size-xl6-5: 2.5rem; /* 40px */
  --font-size-xl7: 3rem; /* 48px */
  --font-size-xl7-5: 3.5rem; /* 56px */
  --font-size-xl8: 4rem; /* 64px */
  --font-size-xl8-5: 4.5rem; /* 72px */
  --font-size-xl9: 5rem; /* 80px */
  --font-size-xl9-5: 5.5rem; /* 88px */
  --font-size-xl10: 6rem; /* 96px */
  --font-size-xl11: 7rem; /* 112px */
  --font-size-xl12: 8rem; /* 128px */

  --font-itc-clear-face-regular: ITC Clearface Std;

  --marketing-border-color: #d9d9d9;

  --builder-underline-offset: 0.25em;

  --editor-topbar-background: #f1f1f1;
  --editor-topbar-backbutton: 9rem; // 144px
  --editor-topbar-border-color: #4c4c4c;
  --editor-topbar-input-bg: #1a1a1a;
  --editor-topbar-content: #e6e6e6;
  --editor-topbar-boxshadow: 0px 2px 21px 0px rgba(0, 0, 0, 0.25);

  --builder-sidemenu-width: 12.5rem; // 200px
  --builder-sidemenu-item-padding-block: 0.625rem; // 10px

  --builder-onboarding-header-sm: 5.25rem; // 84px
  --builder-onboarding-header-lg: 6.25rem; // 100px

  --builder-onboarding-footer-sm: 5.25rem; // 84px
  --builder-onboarding-footer-lg: 6.25rem; // 100px

  --builder-onboarding-sidebar-width: 24rem; // 384px

  --builder-sidebar-width: 23.438rem; // 375px
  --builder-sidebar-width-xl: 26.25rem; // 420px
  --builder-sidebar-width-wide: 34rem; // 544px
  --builder-sidebar-wide-width: 40rem; // 640px

  --builder-sidebar-spacing-block: 1rem; // 16px
  --builder-sidebar-spacing-inline: 1rem; // 24px
  --builder-sidebar-spacing-block-lg: 2rem; // 32px
  --builder-sidebar-spacing-inline-lg: 1.5rem; // 24px
  --builder-sidebar-spacing-block-xl: 2rem; // 32px
  --builder-sidebar-spacing-inline-xl: 2rem; // 32px

  --builder-sidebar-draggable-item-height: 3.75rem; // 60px
  --builder-sidebar-draggable-item-height-lg: 4.5rem; // 72px
  --builder-sidebar-draggable-item-height-compact: 3rem; // 48px
  --builder-sidebar-draggable-extras-height: 11.25rem; // 180px

  --builder-toolbar-height: 2.75rem; // 44px
  --builder-button-height: 2.75rem; // 44px
  --builder-input-height: 2.75rem; // 44px

  --builder-formactions-button-height: 3rem; // 48px

  --builder-draggable-image-size: 2.25rem; // 36px
  --builder-draggable-active-border-width: 0.375rem; // 6px

  --builder-appmenu-background: hsl(0, 0%, 98%);
  --builder-previewpanel-background: hsl(0, 0%, 98%);

  --builder-panel-max-width: 52rem;
  --builder-screen-max-width: 60rem; // 960px

  --builder-btn-color: #fff;
  --builder-input-back: #f9f9f9;
  --builder-input-border-color: #d9d9d9;
  --builder-input-border-width: 2px;

  --builder-border-color: hsl(0, 0%, 92%);
  --builder-border-radius: 0.375rem; // 6px
  --builder-border-radius-xs: 0.125rem; // 2px
  --builder-border-radius-sm: 0.25rem; // 4px
  --builder-border-radius-lg: 0.5rem; // 8px

  --builder-pagepreview-border-color: rgba(0, 0, 0, 0.1);
  --builder-pagepreview-border-color-active: var(--bs-primary);
  --builder-pagepreview-aspectratio: 4/3;
  --builder-pagepreview-maxwidth: 23.75rem; // 380px
  --builder-pagepreview-pages-maxwidth: 22rem; // 352px

  --builder-footer-color: #fff;
  --builder-footer-link-size: 1.1875rem;

  --builder-quaternary: #e7e7e7;

  --builder-boxshadow: 0 4px 16px rgba(0, 0, 0, 0.1), 0 1px 6px rgba(0, 0, 0, 0.1);

  --builder-color-dropdown-link-active-bg: rgba(231, 252, 255, 1);

  --bs-btn-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-active-color: #fff;

  --bs-nav-link-font-size: 1.35rem;
  --builder-height-button: 2.75rem; // 44px

  --alert-success-bg-color: #e9f5e8;
  --alert-success-color: #318e2a;
  --builder-color-bg-button-secondary: #e5e5e5;
  --builder-color-past-due: #981b1b;
  --builder-color-badge-past-due: #f9e5e5;
  --builder-color-badge-color-cancel: #4d4d4d;
  --builder-color-badge-bg-cancel: #efefef;

  --builder-custom-light-blue: #dbe6ff;
  --builder-custom-light-green: #e0febc;

  --builder-banner-bg-danger: #f9685b;
  --builder-banner-bg-importing: #fff1d3;
  --builder-banner-color-importing: #a2451d;

  --builder-cover-height-xxs: 7rem; // 128px;
  --builder-cover-height-xs: 14rem; // 160px;
  --builder-cover-height-sm: 16rem; // 256px;
  --builder-cover-height-md: 19rem; // 304px;
  --builder-cover-height-xl: 22rem; // 352px;

  --builder-grid-cover-height-sm: 8rem; // 128px;
  --builder-grid-cover-height: 22.813rem; // 365px;

  --builder-hero-cover-height-sm: 12.5rem; // 200px
  --builder-hero-cover-height-md: 15rem; // 240px
  --builder-hero-cover-height-lg: 18rem; // 288px
  --builder-hero-cover-height-xl: 22rem; // 352px

  --builder-hero-only1cover-height: 26rem; // 420px

  --coverstyle-hard-boxshadow-sm: 18.991px 16.16px 1.948px 0px rgba(0, 0, 0, 0.25);
  --coverstyle-hard-boxshadow-lg:
    36.056px 40.679px 3.698px 0px rgba(0, 0, 0, 0.25),
    11px 22px 34px 0px rgba(0, 0, 0, 0.12);

  --coverstyle-soft-boxshadow-sm: 26px 24px 36px 0px rgba(0, 0, 0, 0.25);
  --coverstyle-soft-boxshadow-lg: 50px 44px 36px rgba(0, 0, 0, 0.25);

  --builder-hero-font-family: 'Crimson Text';

  --site-max-width-desktop: 90rem; // 1440px Original: 2560px
  --site-max-width-tablet: 62rem; // 992px
  --site-max-width-wide: 120rem; // 1920px

  --builder-color-gray7: #666;
  --builder-color-recommendation: #cc391f;

  --builder-height-preview-theme: 5rem;
}

:root {
  --grays-active: #5b6af9;
  --grays-white: #ffffff;
  --grays-gray-00-f9f9f9: #f9f9f9;
  --grays-gray-01-efefef: #efefef;
  --grays-gray-02-e5e5e5: #e5e5e5;
  --grays-gray-03-d9d9d9: #d9d9d9;
  --grays-gray-04-b3b3b3: #b3b3b3;
  --grays-gray-05-a6a6a6: #a6a6a6;
  --grays-gray-06-8c8c8c: #8c8c8c;
  --grays-gray-07-666666: #666666;
  --grays-gray-08-4d4d4d: #4d4d4d;
  --grays-gray-09-333333: #333333;
  --grays-gray-10-1a1a1a: #1a1a1a;
  --grays-back: rgba(247, 247, 247, 0.8);
  --error: #b40f0f;
  --success: #318e2a;
}

// THIS IS STILL NOT READY FOR USE
// @media (prefers-color-scheme: dark) {
//   :root {
//     --bs-body-color: var(--bs-black);

//     --grays-active: #5b6af9;
//     --grays-white: #ffffff;
//     --grays-gray-00-f9f9f9: #1a1a1a;
//     --grays-gray-01-efefef: #333333;
//     --grays-gray-02-e5e5e5: #4d4d4d;
//     --grays-gray-03-d9d9d9: #666666;
//     --grays-gray-04-b3b3b3: #8c8c8c;
//     --grays-gray-05-a6a6a6: #a6a6a6;
//     --grays-gray-06-8c8c8c: #b3b3b3;
//     --grays-gray-07-666666: #d9d9d9;
//     --grays-gray-08-4d4d4d: #e5e5e5;
//     --grays-gray-09-333333: #efefef;
//     --grays-gray-10-1a1a1a: #f5f5f5;
//     --grays-back: rgba(247, 247, 247, 0.8);
//     --error: #dc4848;
//     --success: #318e2a;
//   }
// }
