.marketing {
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 4.688rem;
  }

  main {
    padding-inline: 0;
    max-width: 1440px;
  }

  .landing {
    padding-top: var(--padding-xl2);

    h1 {
      margin: auto;
      margin-bottom: var(--padding-md);
      font-size: var(--font-size-base);
      font-weight: 400;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 1.625rem;
      }
    }
  }

  // TODO: REVIEW
  .btn_mini {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.75rem;
  }
  .marketing {
    header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      min-height: 5rem;
      border: 1px solid #f5f2f2;
      background: #fff;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .formInner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--padding-base);
    margin: auto;
    margin-top: 2.8rem;
    max-width: 23rem;

    .getStartedImage {
      position: absolute;
      right: 1rem;
      top: -3.3rem;
      width: 10rem;
      pointer-events: none;
    }

    & > div {
      flex-grow: 1;
      @include media-breakpoint-up(lg) {
        flex-grow: 0;
      }
      @include media-breakpoint-up(md) {
        &:nth-child(3) {
          text-align: right;
          @include media-breakpoint-down(lg) {
            flex-grow: 1;
          }
          .btn {
            min-width: 14rem;
            @include media-breakpoint-down(lg) {
              margin-top: calc(var(--padding-md) * -2);
            }
            width: auto !important;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    p {
      margin-bottom: 0;
      font-size: var(--font-size-sm);
      text-align: center;

      a {
        margin-inline-start: var(--padding-xs);
      }
    }

    a {
      font-size: var(--font-size-sm);
      text-underline-offset: 0.125em;
      text-align: center;
    }

    .form-control {
      width: 100%;
      padding-inline: var(--padding-base);
      box-shadow: none;
      @include media-breakpoint-up(lg) {
        // max-width: 18rem;
      }

      &:focus {
        border-color: var(--bs-gray-600);
      }
    }
    .form-floating > label {
      padding-inline: var(--padding-base);
      color: #333;
      font-size: var(--font-size-base);
      @include media-breakpoint-up(md) {
        font-size: 1.17856rem;
      }
    }
    .form-floating > input {
      padding-top: var(--padding-xl);
    }
    .formInner {
      .btn {
        min-height: 3.75rem;
        font-weight: 600;
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: var(--padding-base);
    margin-block: var(--padding-xl3);
    padding-inline: var(--padding-md);
    user-select: none;
    --bs-border-width: 2px;
    color: var(--tfa-color-text);
    font-weight: 400;
    @include media-breakpoint-up(md) {
      margin-bottom: var(--padding-xl3);
    }

    p {
      font-size: 1.625rem;
    }

    .sectionTitle {
      margin-bottom: var(--padding-base);
      font-size: var(--font-size-xl4);
      font-family: var(--font-itc-clear-face-regular);
      line-height: 110%;
      letter-spacing: -0.05rem;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 3.75rem;
        letter-spacing: -0.09rem;
      }
    }

    .titleDivider {
      position: relative;
      margin-top: var(--padding-md);
      text-align: center;
      font-size: var(--font-size-lg);
      letter-spacing: 0.25rem;
      border-top: 1px solid var(--marketing-border-color);

      span {
        display: block;
        margin-inline: auto;
        margin-top: -0.8rem;
        padding-inline: var(--padding-xl);
        width: fit-content;
        background-color: var(--bs-white);
      }
    }

    ul {
      padding: 0;
      list-style: none;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .instantWebsites {
    margin-top: 0;

    .sectionTitle {
      margin: auto;
      font-size: 2.375rem;
      line-height: 110%;
      font-family: var(--font-itc-clear-face-regular);
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 3.75rem;
      }
    }

    .sectionBody {
      margin-inline: auto;
      font-size: 1.0625rem;
      font-weight: 400;
      line-height: 132%;
      text-align: center;
      @include media-breakpoint-up(md) {
        width: 32.75rem;
        font-size: 1.625rem;
      }
    }
  }

  .carousel {
    margin-bottom: var(--padding-xl2);
    user-select: none;

    .carousel-caption {
      padding: 0;
      top: 8%;
      right: 0;
      left: 0;
      bottom: auto;

      @include media-breakpoint-up(sm) {
        top: 5%;
      }

      @include media-breakpoint-up(md) {
        top: 6.5%;
      }

      p {
        margin-bottom: 0;
        padding-inline: var(--padding-base);
        font-size: 0.8rem;
        line-height: 132%;
        font-weight: 400;
        @include media-breakpoint-up(sm) {
          font-size: 0.9375rem;
        }
        @include media-breakpoint-up(md) {
          font-size: 1.625rem;
        }
      }

      b {
        font-weight: 600;
      }
    }

    .carousel-indicators {
      gap: 0.625rem;
      top: 18%;
      bottom: auto;
      @include media-breakpoint-up(sm) {
        gap: 1rem;
        top: 15%;
      }
      @include media-breakpoint-up(md) {
        top: 14%;
      }

      button {
        margin: 0;
        width: 0.5rem;
        height: 0.5rem;
        border: 0;
        border-radius: var(--bs-border-radius-pill);

        &:not(.active) {
          background: var(--bs-black);
          opacity: 0.4;
        }

        @include media-breakpoint-up(lg) {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }

    .carousel-item {
      img {
        margin-top: -10%;
        min-height: 320px;
        object-fit: cover;

        @include media-breakpoint-up(sm) {
          margin-top: -13%;
          min-height: 300px;
        }
      }
    }
  }

  .writingBooks {
    margin-top: var(--padding-xl5);

    .sectionTitle {
      margin-bottom: var(--padding-xl2);
    }

    .bigSideImage {
      margin-inline: auto;

      li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: var(--padding-lg);
        margin-bottom: var(--padding-xl2);
        @include media-breakpoint-up(md) {
          gap: var(--padding-xl2);
          margin-bottom: var(--padding-xl3);
        }
      }

      img {
        margin-top: var(--padding-xs);
        max-width: 75px;
        @include media-breakpoint-up(md) {
          max-width: 115px;
        }
      }

      p {
        margin-bottom: 0;
        max-width: 22rem;
        @include media-breakpoint-up(md) {
          max-width: 41rem;
        }
      }

      b {
        display: inline-block;
        margin-bottom: var(--padding-xs2);
      }
    }
  }

  .featuresRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--padding-lg);
    margin-top: var(--padding-xl2);
    margin-bottom: var(--padding-lg);
    margin-inline: auto;
    max-width: 69rem;
    text-align: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    li {
      max-width: 22rem;
    }

    p {
      margin-bottom: 0;
    }

    b {
      display: block;
      margin-bottom: var(--padding-xs2);
    }
  }

  .shortDivider {
    margin-inline: auto;
    border-top: 1px solid var(--marketing-border-color);
    max-width: 10rem;
  }

  .hereToHelp,
  .friendlyPricing {
    margin-inline: auto;
    max-width: 52rem;
    text-align: center;
  }

  .helpTeam {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--padding-lg);
    margin-inline: auto;

    img {
      border-radius: var(--bs-border-radius-pill);
      @include media-breakpoint-up(md) {
        max-width: 11rem;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .aboutTertulia {
    padding-block: var(--padding-xl);
    text-align: center;
    border: 1px solid var(--marketing-border-color);
    border-right: 0;
    border-left: 0;
    @include media-breakpoint-up(md) {
      padding-block: var(--padding-xl4);
    }

    .sectionTitle {
      @include media-breakpoint-up(md) {
        margin-bottom: var(--padding-xl);
      }
    }

    .row {
      flex-direction: column;
      gap: var(--padding-xl);
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }

  .freeTrial {
    .formInner {
      margin-top: auto;
    }

    .sectionBody {
      margin-inline: auto;
      text-align: center;
      max-width: 33rem;
    }

    .getStartedImage {
      display: none;
    }
  }

  .emailSignup {
    margin-inline: auto;
    padding-bottom: var(--padding-xl6);
    max-width: 40rem;
    button {
      min-width: 6.45rem;
    }
    .emailSignupForm {
      margin: var(--padding-base);
      display: flex;
      gap: var(--padding-base);
      justify-content: center;
    }
  }

  .landingFaq {
    .titleDivider {
      margin-top: var(--padding-xl1);
      margin-bottom: var(--padding-xl);
    }

    .faq-container {
      margin-inline: auto;
      padding-inline: 0;
      width: 100%;
      max-width: 60.5rem;

      .faq-title {
        text-align: center;
        font-size: var(--font-size-md);
        letter-spacing: 0.25rem;

        @include media-breakpoint-up(md) {
          font-size: var(--font-size-lg);
        }
      }

      li {
        padding-inline: var(--padding-xs);
      }

      .faq-answer {
        ul {
          list-style: disc;
          margin-inline-start: var(--padding-lg);
        }
        li {
          margin-bottom: var(--padding-base);
          padding-inline: 0;
        }
      }

      @include media-breakpoint-up(md) {
        li > a {
          padding-block: var(--padding-xl);
          font-size: var(--font-size-xl3);
          line-height: 130%;
        }

        .faq-answer {
          margin-bottom: var(--padding-xl);
          font-size: var(--font-size-xl3);
          line-height: 130%;
        }
      }
    }
  }
}

.carousel-testimonials {
  background-color: #fafafa;
  margin-bottom: var(--padding-xl3);
  padding: var(--padding-lg);
  padding-top: var(--padding-xl2);
  border-width: 2px 0;
  border-style: solid;
  border-color: var(--marketing-border-color);
  @include media-breakpoint-up(md) {
    padding: var(--padding-xl2);
  }
  .carousel-indicators {
    top: 100% !important;
    bottom: 0 !important;
    .active {
      background-color: var(--bs-black);
    }
  }
  h3 {
    font-weight: 600;
    font-size: var(--font-size-base);
    line-height: var(--font-size-base);
    text-align: center;
    margin-bottom: var(--padding-lg);

    @include media-breakpoint-up(md) {
      font-size: var(--font-size-xl3);
      line-height: var(--font-size-xl2);
      margin-bottom: var(--padding-xl);
    }
  }
  .carousel {
    margin-bottom: var(--padding-base);
    @include media-breakpoint-up(md) {
      margin-bottom: var(--padding-xl);
    }
  }
  .carousel-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      min-height: auto !important;
      margin-top: 0 !important;
      width: 90px;
      height: 90px;
      @include media-breakpoint-up(md) {
        width: 105px;
        height: 105px;
      }
    }
    p {
      max-width: 79rem;
      &:first-of-type {
        font-weight: 400;
        font-size: var(--font-size-sm);
        line-height: var(--font-size-xl);
        margin-top: var(--padding-base);

        @include media-breakpoint-up(md) {
          font-size: var(--font-size-xl3);
          line-height: var(--font-size-xl6);
        }
      }
      &:last-child {
        font-weight: 400;
        font-size: var(--font-size-base);
        line-height: var(--font-size-xl2);

        @include media-breakpoint-up(md) {
          font-size: var(--font-size-xl6);
          line-height: var(--font-size-xl6-5);
        }
      }
    }
    &:not(.active) {
      opacity: 0 !important;
    }
  }
}
